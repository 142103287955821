import { useReactiveVar } from '@apollo/client'
import { fonts } from '@lumoslabs/lumosity-storybook'
import { NextPage } from 'next'
import getConfig from 'next/config'
import Head from 'next/head'
import { useCookies } from 'react-cookie'

import Footer from '~/components/Footer'
import QueryLoader from '~/components/QueryLoader'
import StudyComplete from '~/components/ResearchOnly/StudyComplete'
import StreakCard from '~/components/streaks/StreakCard'
import TopNav from '~/components/TopNav'
import PageContent from '~/components/ui/PageContent'
import WorkoutCard from '~/components/ui/WorkoutCard'
import { playableGameSlugs } from '~/gameConfigs/GamesLibrary'
import { useGetScriptedWorkoutHistoryQuery, useGetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { currentUserVar } from '~/graphql/reactive-vars/userVar'
import useTrackScreenView from '~/hooks/analytics/useTrackScreenView'
import useLocalDate from '~/hooks/useLocalDate'
import useScrollToTop from '~/hooks/useScrollToTop'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { useVideoWorkoutScript } from '~/hooks/useVideoWorkoutScript'
import { withApollo } from '~/lib/withApollo'
import { parseUser } from '~/utils/loginUtils'
import { getTotalGamesPlayed } from '~/utils/workoutUtils'

const { Heading1, Subheading2 } = fonts

const { publicRuntimeConfig } = getConfig()

interface HomeProps {
  name?: string
  date: Date
}

const Home: NextPage<HomeProps> = () => {
  const isResearch = publicRuntimeConfig.researchMode
  // not actually the page slug since this is the root page, but calling it home since that's the tracking name!
  const pageSlug = 'home'
  const t = useTranslationForNamespace(pageSlug)
  useTrackScreenView(pageSlug, 'side_nav')
  const currentUser = useReactiveVar(currentUserVar)
  const name = currentUser?.firstName ?? ''
  const { dateFormatted, greetingKey } = useLocalDate()
  useScrollToTop()

  const {
    data: scriptedWorkoutHistoryData,
    loading: historyLoading,
    error: historyError,
  } = useGetScriptedWorkoutHistoryQuery({
    fetchPolicy: 'cache-and-network',
    // see https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',
  })

  const showStudyComplete = isResearch && scriptedWorkoutHistoryData?.scriptedWorkoutHistory.studyComplete

  const greeting = name ? t(`greetingWithName.${greetingKey()}`, { name: name }) : t(`greeting.${greetingKey()}`)
  const [cookie] = useCookies(['lumosUser'])
  const lumosUser = parseUser(cookie.lumosUser)
  const group = lumosUser?.group

  return (
    <>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <TopNav />
      <PageContent>
        <PageContent.Header>
          <Subheading2>{dateFormatted}</Subheading2>
          <Heading1 as='h1'>{greeting}</Heading1>
        </PageContent.Header>
        <PageContent.Main>
          {showStudyComplete ? (
            <QueryLoader data={scriptedWorkoutHistoryData} loading={historyLoading} error={historyError}>
              <StudyComplete />
            </QueryLoader>
          ) : group === 1 ? (
            <GameWorkoutCardLoader />
          ) : (
            <VideoWorkoutCardLoader />
          )}
        </PageContent.Main>
        {scriptedWorkoutHistoryData && !showStudyComplete && (
          <PageContent.Sidebar>
            <StreakCard />
          </PageContent.Sidebar>
        )}
      </PageContent>
      {isResearch && <Footer />}
    </>
  )
}

const GameWorkoutCardLoader = () => {
  const { data, loading, error } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: playableGameSlugs },
    fetchPolicy: 'cache-and-network',
    // see https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',
  })

  if (data) {
    const totalGameCount = 7
    const totalGamesPlayed = getTotalGamesPlayed(data)
    const remainingActivityCount = totalGameCount - totalGamesPlayed
    const workoutNotStarted = remainingActivityCount === totalGameCount
    const workoutComplete = remainingActivityCount === 0

    return (
      <WorkoutCard
        showGameIcon={true}
        remainingActivityCount={remainingActivityCount}
        workoutNotStarted={workoutNotStarted}
        workoutComplete={workoutComplete}
      />
    )
  } else {
    return (
      <QueryLoader data={data} loading={loading} error={error}>
        <></>
      </QueryLoader>
    )
  }
}

const VideoWorkoutCardLoader = () => {
  const { data, loading, error } = useVideoWorkoutScript()

  if (data) {
    const { workoutResumeIndex, script } = data
    const remainingActivityCount = script.videos.length - workoutResumeIndex
    const workoutNotStarted = workoutResumeIndex === 0
    const workoutComplete = remainingActivityCount === 0

    return (
      <WorkoutCard
        showGameIcon={false}
        remainingActivityCount={remainingActivityCount}
        workoutNotStarted={workoutNotStarted}
        workoutComplete={workoutComplete}
      />
    )
  } else {
    return (
      <QueryLoader data={data} loading={loading} error={error}>
        <></>
      </QueryLoader>
    )
  }
}

export default withApollo({ ssr: true })(Home)
