import { fonts } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import ButtonLink from '~/components/ButtonLink'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import WorkoutCompletionAnimation from '~/components/ui/WorkoutCompletionAnimation'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import ChevronRight from '~/images/chevron_right.svg'
import GameGroupIcon from '~/images/Workout/GameGroupIcon.svg'

const { Body3, Body3Caps, Heading3 } = fonts

export interface WorkoutCardProps {
  remainingActivityCount: number
  workoutNotStarted: boolean
  workoutComplete: boolean
  showGameIcon: boolean
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({
  remainingActivityCount,
  workoutNotStarted,
  workoutComplete,
  showGameIcon,
}) => {
  const t = useTranslationForNamespace('home')
  const CTA = workoutNotStarted ? 'begin' : 'continue'

  if (workoutComplete) {
    const headingText = t('workoutCard.headings.today.workoutComplete')
    const subheadingText = t('workoutCard.subheadings.today.workoutComplete')

    return (
      <WorkoutCardContainer>
        <WorkoutCompleteAnimationContainer>
          <WorkoutCompletionAnimation />
        </WorkoutCompleteAnimationContainer>
        <Content>
          <Heading3>{headingText}</Heading3>
          <Body3>{subheadingText}</Body3>
        </Content>
      </WorkoutCardContainer>
    )
  } else {
    const headingText = t('workoutCard.headings.today.workoutIncomplete')
    const buttonText = t(`common:buttons.${CTA}`)

    // Don't include remains if workout hasn't been started
    const gamesRemainingText = workoutNotStarted
      ? t('workoutCard.subheadings.today.gamesRemainingInitial', { count: remainingActivityCount })
      : t('workoutCard.subheadings.today.gamesRemaining', { count: remainingActivityCount })

    return (
      <WorkoutCardContainer>
        <WorkoutIconContainer showGameIcon={showGameIcon}></WorkoutIconContainer>
        <Content>
          <Heading3 style={{ width: 'max-content' }}>{headingText}</Heading3>
          <Details>
            <GameGroupIcon title='game_group_icon' />
            <Body3Caps>{gamesRemainingText}</Body3Caps>
          </Details>
          <CTAButton>
            <ButtonLink href='/workout' wide={true} rightBadge={<ChevronRight title={CTA} height='12px' />}>
              {buttonText}
            </ButtonLink>
          </CTAButton>
        </Content>
      </WorkoutCardContainer>
    )
  }
}

export default WorkoutCard

const CTAButton = styled.div`
  padding-top: 20px;
  width: 80%;
  min-width: 230px;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    padding-top: 12px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    padding-top: 12px;
  }
`

const Content = styled.div`
  padding: 5%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  color: ${({ theme }) => theme.colors.inkBase};
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: 20px 50px 30px 50px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    padding: 20px 50px 30px 50px;
  }
`

const Details = styled.div`
  color: ${({ theme }) => theme.colors.coolGray44};
  display: flex;
  gap: 8px;
`

const WorkoutCardContainer = styled(CardShadowRadius)`
  position: relative;
  max-height: 228px;
  display: flex;
  flex-direction: row;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    max-height: 324px;
    height: 100%;
    flex-direction: column;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    max-height: 324px;
    height: 100%;
    flex-direction: column;
  }
`

const WorkoutIconContainer = styled.div<{ showGameIcon: boolean }>`
  ${({ showGameIcon }) => (showGameIcon ? 'display: block;' : 'display: none;')}
  width: 33%;
  background-image: url(${({ showGameIcon }) =>
    showGameIcon ? '/assets/images/workout/workout-card-bg-color-burn.png' : ''});
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  background-position: 30% 75%;
  background-size: 235%;
  display: flex;
  justify-content: center;
  padding: 3%;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    width: 100%;
    height: 45%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    background-position: 15% 30%;
    background-size: 105%;
    padding: 10px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
    height: 45%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    background-position: 15% 30%;
    background-size: 105%;
    padding: 10px;
  }
`

const WorkoutCompleteAnimationContainer = styled.div`
  width: 33%;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  display: flex;
  justify-content: center;
  padding: 3%;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.desktop} and (min-width: 851px)`} {
    width: 100%;
    height: 45%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    padding: 10px;
  }
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
    height: 45%;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    padding: 10px;
  }
`
