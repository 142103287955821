import { useEffect } from 'react'

import { animated, useTransition } from '@react-spring/web'
import lottie from 'lottie-web'
import styled from 'styled-components'

import { workoutCompletionTransition } from '~/animation/Workout.animation'
import WorkoutCompleteSvg from '~/images/Workout/workoutComplete.svg'

const WorkoutCompletionAnimation = () => {
  const workoutComplete = true
  /***** TRANSITIONS *****/
  const finishWorkoutTransition = useTransition(workoutComplete, workoutCompletionTransition(true, 1500))

  useEffect(() => {
    const confetti = lottie.loadAnimation({
      container: document.querySelector('#confetti') as any,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/lottieAnimations/confetti.json',
      rendererSettings: {
        viewBoxOnly: true,
      },
    })
    return () => {
      confetti.destroy()
    }
  }, [])

  return (
    <CompletionAnimationContainer>
      {finishWorkoutTransition((styles) => (
        <animated.div style={styles}>
          <WorkoutCompleteSvg width='100%' height='100%' title='workout-complete' />
        </animated.div>
      ))}
    </CompletionAnimationContainer>
  )
}

const CompletionAnimationContainer = styled.div`
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export default WorkoutCompletionAnimation
