export const logToRollbar = (message: string, object?: any) => {
  if (typeof window !== 'undefined' && message) {
    window?.Rollbar?.error?.(message, object, (rollbarError) => {
      if (rollbarError) {
        console.error('Rollbar error reporting failed:')
        console.error(rollbarError)
        return
      }
      console.log('Reported error to Rollbar')
    })
  }
}
