import { fonts } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const { Subheading2 } = fonts

const StudyComplete: React.FunctionComponent = () => {
  const t = useTranslationForNamespace('home')
  return (
    <Content>
      <Subheading2>{t('researchStudyComplete')}</Subheading2>
    </Content>
  )
}

const Content = styled.div`
  padding-bottom: 5%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  ${({ theme }) => `${theme.mediaQuery.maxWidth.mobileLarge}`} {
    width: 100%;
  }
`

export default StudyComplete
