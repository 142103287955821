import React from 'react'

import { fonts } from '@lumoslabs/lumosity-storybook'
import getConfig from 'next/config'
import styled from 'styled-components'

import StreakCalendar from '~/components/streaks/StreakCalendar'
import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'
import useStreakData from '~/hooks/useStreakData'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

const { Body2, Heading3, MicroText1BoldCaps } = fonts

const { publicRuntimeConfig } = getConfig()

const StreakCard = (): JSX.Element => {
  const isResearch = publicRuntimeConfig.researchMode
  const t = useTranslationForNamespace('common')
  const { currentStreakLength, bestStreakLength } = useStreakData()

  return (
    <CardContainer>
      <CardTitle>{isResearch ? t('streaks.researchTitle') : t('streaks.yourStreaks')}</CardTitle>
      <StreakUnderline />
      <CalendarContainer>
        <StreakCalendar inkBackground={false} />
      </CalendarContainer>
      <StreakLengthsContainer>
        {!isResearch && (
          <>
            <Row>
              <Heading3>{t('streaks.day', { count: currentStreakLength })}</Heading3>
              <Heading3>{t('streaks.day', { count: bestStreakLength })}</Heading3>
            </Row>
            <Row>
              <StreakLabelsText>{t('streaks.currentStreak')}</StreakLabelsText>
              <StreakLabelsText>{t('streaks.bestStreak')}</StreakLabelsText>
            </Row>
          </>
        )}
        {isResearch && (
          <Row>
            <StreakLabelsText>{t('streaks.researchMessage')}</StreakLabelsText>
          </Row>
        )}
      </StreakLengthsContainer>
    </CardContainer>
  )
}

const CardContainer = styled(CardShadowRadius)`
  width: 326px;
  height: 220px;
`

const CardTitle = styled(MicroText1BoldCaps)`
  color: ${({ theme }) => theme.colors.coolGray62};
  padding: 18px 18px 8px;
  display: block;
`

const StreakUnderline = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.colors.coolGray92};
  border: none;
`

const StreakLengthsContainer = styled.div`
  margin: 22px 10px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1fr;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  > * {
    flex: auto;
  }
`

const StreakLabelsText = styled(Body2)`
  color: ${(props) => props.theme.colors.coolGray44};
  margin-top: 8px;
  margin-right: 10px;
  margin: 8px 10px 0 10px;
`

const CalendarContainer = styled.div`
  color: ${(props) => props.theme.colors.coolGray44};
`

export default StreakCard
